import axios from 'axios'

const STORE_API = process.env.REACT_APP_STORE_URL || ' '

const getAllCategories = async () => {

    const response = await axios.get(STORE_API + 'product-category/get-categories?skip=0&limit=1000')
  
    return response.data
  }
  
  const createCategory = async (categoryData:any,token:string)=>{
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json"
      },
    }
    const response = await axios.post(STORE_API+'product-category/create',categoryData,config)

    return response.data
  }

  const categoryService = {
    getAllCategories,
    createCategory
  }


  export default categoryService