import axios from 'axios'

const STORE_API = process.env.REACT_APP_STORE_URL || ' '

// Get all 
const getAllLoans = async (token: String) => {
   
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(STORE_API + 'purchase/requests?skip=0&limit=1000&order_by=DATE_CREATED&order_direction=DESC&isPayLater=true&paymentStatus=Pending', config)
    
    return response.data.requests
}
const getAllLoansApproved = async (token: String) => {
   
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
 const response = await axios.get(STORE_API + 'purchase/requests?skip=0&limit=1000&order_by=DATE_CREATED&order_direction=DESC&isPayLater=true&PayLaterApproved=true', config)
    
    return response.data.requests
}
const getAllRequets = async (token: String) => {
   
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
 const response = await axios.get(STORE_API + 'purchase/requests?skip=0&limit=1000&order_by=DATE_CREATED&order_direction=DESC', config)
    
    return response.data.requests
}

const loanService = {
    getAllLoans,
    getAllLoansApproved,
    getAllRequets
  }
  export default loanService