import { Popover, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { Fragment } from 'react'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import boosta_logo from '../images/boosta_logo.svg'



const navigation = [
    { name: 'Product', href: '#' },
    { name: 'Features', href: '#' },
    { name: 'Marketplace', href: '#' },
    { name: 'Company', href: '#' },
  ]

function Header() {
  return (
    <Popover as="header" className="relative">
    <div className="bg-white pt-6">
      <nav
        className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
        aria-label="Global"
      >
        <div className="flex items-center flex-1">
          <div className="flex items-center justify-between w-full md:w-auto">
            <Link to="/">
              <span className="sr-only">Workflow</span>
              <img
                className="h-8 w-auto sm:h-10"
                src={boosta_logo}
                alt="Boosta Company Logo"
              />
            </Link>
            <div className="-mr-2 flex items-center md:hidden">
              <Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
          </div>
        </div>
        <div className="hidden md:flex md:items-center md:space-x-16">
          <Link to="/" className="text-base font-medium text-black hover:text-gray-800">
            Home
          </Link>
          <Link to="/" className="text-base font-medium text-black hover:text-gray-800">
            About
          </Link>
          <Link to="/" className="text-base font-medium text-black hover:text-gray-800">
            Token
          </Link>
          <Link to="/" className="text-base font-medium text-black hover:text-gray-800">
            Pages
          </Link>
          <Link to='/login'
            className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-lg text-white bg-button-color hover:bg-gray-700"
          >
            Sign In
          </Link>
        </div>
      </nav>
    </div>

    <Transition
      as={Fragment}
      enter="duration-150 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top md:hidden">
        <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="px-5 pt-4 flex items-center justify-between">
            <div>
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/workflow-mark-teal-500-cyan-600.svg"
                alt=""
              />
            </div>
            <div className="-mr-2">
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-900 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
          </div>
          <div className="pt-5 pb-6">
            <div className="px-2 space-y-1">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-500"
                >
                  {item.name}
                </a>
              ))}
            </div>
            <div className="mt-6 px-5">
              <Link to='/'
                className="block text-center w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-teal-500 to-cyan-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700"
              >
                Start free trial
              </Link>
            </div>
            <div className="mt-6 px-5">
              <p className="text-center text-base font-medium text-gray-500">
                Existing customer?{' '}
                <Link to="/" className="text-gray-900 hover:underline">
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  </Popover>
  )
}

export default Header