import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import agentStoreService from './agentStoreService'

const initialState = {
    stockAs: [],
    stockA: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

export const getAllStoreStockA = createAsyncThunk(
    'stocks/getAll',
    async (_, thunkAPI: any) => {
      try {
        const token = thunkAPI.getState().auth.user.access_token
        return await agentStoreService.getAllStoreStockA(token)
      } catch (error: any) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        return thunkAPI.rejectWithValue(message)
      }
    }  
)


export const stockASlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
      reset: (state) => initialState
  },
  extraReducers: (builder) => {
      builder.addCase(getAllStoreStockA.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllStoreStockA.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.stockAs = action.payload
        })
        .addCase(getAllStoreStockA.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        
  },
})

export const {reset} = stockASlice.actions

export default stockASlice.reducer