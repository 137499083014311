import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead  from '@mui/material/TableHead';
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePaginationActions from "../components/TablePaginationActions";
import TablePagination from "@mui/material/TablePagination";
import { useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'react-spinkit'
import { getAllRequets } from "../slices/Loans/loanSlice";
import { useParams} from "react-router-dom"

function MerchantTransactions() {
  const {loans, isLoading, isSuccess} = useSelector(
    (state) => state.loans)

    const {userID} = useParams();
    const requests = loans.filter((request)=>request.merchant.user.userID===userID)

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const dispatch = useDispatch()

  // useEffect(() => {
  //   return () => {
  //     if (isSuccess) {
  //       dispatch(reset())
  //     }
  //   }
  // }, [dispatch, isSuccess])

  useEffect(() => {
    dispatch(getAllRequets())
  }, [dispatch])

//   console.log(requests)


  if (isLoading) {
    return (
    <div className="grid place-items-center h-full w-full">
    <Spinner 
    name="ball-spin-fade-loader"
    color="blue"
    fadeIn="none"
    className="flex-col pt-32 grid place-items-center items-center justify-center  h-screen"
    />
    </div>
    )
  }

  return (

    <div className="relative z-0 h-screen overflow-hidden bg-trans-back">
    
    <div className='mt-1'>
    <main className="relative z-0 overflow-y-auto focus:outline-none">
      <div className='flex flex-col'>
        
        <div className='flex flex-row'>
          <div className="px-4 sm:px-6 lg:px-8 overflow-y-hidden bg-white mx-auto w-[75vw] rounded-xl">
                <div className="sm:flex sm:items-center lg:space-x-16">                          
                    <div className="mt-1 py-1 text-sm p-2 lg:w-1/4 text-button-black flex flex-row space-x-3">
                    <h1 className='text-2xl'>Merchant Transactions</h1>  
                    </div>
                  
                </div> 
                <div className="mt-4 flex flex-col">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <TableContainer component={Paper}>
                        <Table className="min-w-full divide-y divide-gray-300">
                          <TableHead className="bg-gray-50">
                            <TableRow>
                              <TableCell  scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              DATE OF PURCHASE
                              </TableCell>
                              <TableCell  scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              MERCHANT NAME
                              </TableCell>
                              <TableCell  scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              AMOUNT
                              </TableCell>
                              {/* <TableCell  scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              REPAYMENT Date
                              </TableCell> */}
                              <TableCell  scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              ASSIGNED AGENT
                              </TableCell>
                              {/* <TableCell  scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              PAY LATER CONFIRMED
                            </TableCell> */}
                              <TableCell  scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              PAYMENT STATUS
                            </TableCell>
                              {/* <TableCell scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <p>{'    '}</p>
                              </TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody className="divide-y divide-gray-200 bg-white mb-4 pb-4">
                          
                            {(rowsPerPage > 0 ? requests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : requests ).map((loan) => (
                              <TableRow key={loan.id}>
                                <TableCell className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {loan.createdAt.substring(0,10)}
                                </TableCell>
                                <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> {loan.merchant.user.firstName}{' '}{loan.merchant.user.lastName} </TableCell>
                                <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{loan.totalAmountExpected}</TableCell>
                                {/* <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{loan.repaymentDate && loan.repaymentDate.substring(0,10)}</TableCell> */}
                                <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{loan.agent && loan.agent.user.firstName}{' '}{loan.agent && loan.agent.user.lastName}</TableCell>
                                {/* <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{String(loan.PayLaterApproved) && String(loan.PayLaterApproved)}</TableCell> */}
                                <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{loan.paymentStatus}</TableCell>
                                {/* <TableCell className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <Link to={`/dashboard/merchants/${loan.id}`} className="text-indigo-600 hover:text-indigo-900 underline text-md">
                                 <p>View</p>

                                  </Link>
                                </TableCell> */}
                              </TableRow>
                            ))}

                            {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}

                          </TableBody>
                          <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[5, 7, 8, { label: "All", value: -1 }]}
                              colSpan={4}
                              count={requests.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                                native: true,
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                        </Table>
                      </TableContainer>
               
                      </div>
                    </div>
                  </div>
                </div>
          </div>
  
        </div>
      </div>
      {/* End main area */}
    </main>
   
    </div>

     
    </div>
  );
}

export default MerchantTransactions;