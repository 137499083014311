// import filterIcon  from '../images/filterIcon.svg'
// import checkBoxIcon  from '../images/checkBoxIcon.svg'
// import threedotsIcon  from '../images/threedotsIcon.svg'
// import { useState } from 'react'
// import Pagination from '../components/pagination'
// import ApprovedLoansRecords from '../components/ApprovedLoansRecords'

// const products = [
//     {id:'safafa-afsafa-agqegq',Date: '2022-08-01', merchantName: 'Dayo  Idris', amount: '100k Naira', paymentDuration: '4 Months', assignedAgent: 'Usman Don Fodio', quantity:23, status: true},
//     {id:'safafa-afsafa-agqgwwggq',Date: '2022-08-01', merchantName: 'Aishat Usman', amount: '163k Naira', paymentDuration: '8 Months', assignedAgent: 'Chiagozie Jude', quantity:23, status: true},
//     {id:'safafafegw-afsafa-agqegq', Date: '2022-06-01', merchantName: 'Segun Olakunle', amount: '150k Naira', paymentDuration: '5 Months', assignedAgent: 'Samson Ude', quantity:23, status: true},
//     {id:'safafa-afsafa-agqesfqfqgq', Date: '2022-04-01', merchantName: 'Popoola Tunde', amount: '180k Naira', paymentDuration: '6 Months', assignedAgent: 'Jude Okolo', quantity:23, status: true},
//     {id:'safafa-afsafa-agqes232gq', Date: '2022-04-01', merchantName: 'Ifeanyi Ugo', amount: '280k Naira', paymentDuration: '7 Months', assignedAgent: 'Babatunde Ismaili', quantity:23, status: true},
//     {id:'safafa-afsafa-afe2gesfqfqgq', Date: '2022-01-01', merchantName: 'Chiamaka Johnson', amount: '30k Naira', paymentDuration: '4 Months', assignedAgent: 'Wole Ogunwole', quantity:23, status: true},
//     {id:'safa-affa-agqegq', Date: '2022-01-01', merchantName: 'John Ahmed', amount: '70k Naira', paymentDuration: '3 Months', assignedAgent: 'Joshua Kennedy', quantity:23, status: true},
//   ]

// function ApprovedLoans() {
//     // User is currently on this page
//     const [currentPage, setCurrentPage] = useState(1);
//     // No of Records to be displayed on each page   
//     const [recordsPerPage] = useState(5);
//     const indexOfLastRecord = currentPage * recordsPerPage;
//     const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
//     // Records to be displayed on the current page
//     const currentRecords = products.slice(indexOfFirstRecord, 
//     indexOfLastRecord);
//     const nPages = Math.ceil(products.length / recordsPerPage)
//   return (
    
//     <div className='flex flex-row'>
//     <div className="px-4 sm:px-6 lg:px-8 overflow-y-hidden bg-white mx-auto w-[75vw] rounded-xl">
//           <div className="sm:flex sm:items-center lg:ml-auto lg:w-[15vw] mt-2 py-4">                          

//             <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none mr-0">
//             <img className='' src={filterIcon} alt="" />
//             </div>
//           </div>
//           <div className="mt-4 flex flex-col">
//             <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
//               <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
//                 <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
//                   <table className="min-w-full divide-y divide-gray-300">
//                     <thead className="bg-gray-50">
//                       <tr>
//                       <th scope="col" className="py-3.5 pl-4text-gray-900 sm:pl-6">
//                          <img src={checkBoxIcon} alt="CheckBox Icon"/>
//                         </th>
//                         <th scope="col" className="py-3.5 pr-3 text-left text-sm font-normal text-gray-900">
//                          DATE
//                         </th>
//                         <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
//                           MERCHANT NAME
//                         </th>
//                         <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
//                         AMOUNT
//                       </th>
//                       <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
//                         PAYMENT DURATION
//                       </th>
//                       <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
//                       ASSIGNED AGENT
//                       </th>
//                       <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
//                       CREDIT SCORE
//                      </th>
//                       <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
//                        STATUS
//                       </th>
//                         <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
//                           <img src={threedotsIcon} alt=""/> 
//                         </th>
//                       </tr>
//                     </thead>
//                     <ApprovedLoansRecords products={currentRecords}/>
//                   </table>
//                   <Pagination
//                   nPages={nPages}
//                   currentPage={currentPage}
//                   setCurrentPage={setCurrentPage}
//               />
//                 </div>
//               </div>
//             </div>
//           </div>
//     </div>

//   </div>
//   )
// }

// export default ApprovedLoans

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead  from '@mui/material/TableHead';
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePaginationActions from "../components/TablePaginationActions";
import TablePagination from "@mui/material/TablePagination";
import { useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'react-spinkit'
import { getAllLoansApproved } from "../slices/Loans/loanSlice";

function ApprovedLoans() {
  const {loans, isLoading, isSuccess} = useSelector(
    (state) => state.loans)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - loans.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const dispatch = useDispatch()

  // useEffect(() => {
  //   return () => {
  //     if (isSuccess) {
  //       dispatch(reset())
  //     }
  //   }
  // }, [dispatch, isSuccess])

  useEffect(() => {
    dispatch(getAllLoansApproved())
  }, [dispatch])

  // console.log(loans)


  if (isLoading) {
    return (
    <div className="grid place-items-center h-full w-full">
    <Spinner 
    name="ball-spin-fade-loader"
    color="blue"
    fadeIn="none"
    className="flex-col pt-32 grid place-items-center items-center justify-center  h-screen"
    />
    </div>
    )
  }

  return (

    <div className="relative z-0 h-screen overflow-hidden bg-trans-back">
    
    <div className='mt-1'>
    <main className="relative z-0 overflow-y-auto focus:outline-none">
      {/* Start main area*/}
      <div className='flex flex-col'>
        {/* <div className="py-6 px-4 sm:px-6 lg:px-8 w-full ">
        <div className="flex flex-1 justify-between px-4"> */}
        {/* <div className="flex flex-1">
          <form className="flex w-full lg:ml-0" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search...
            </label>
            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                <MagnifyingGlassIcon className="h-6 w-6 pl-2" aria-hidden="true" />
              </div>
              <input
                id="search-field"
                className="block h-full w-full border-transparent rounded-lg py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm px-4"
                placeholder="Search..."
                type="search"
                name="search"
              />
            </div>
          </form>
        </div> */}
        
        {/* <div className="ml-4 flex items-center lg:ml-6">
          <div
            type="button"
            className="inline-flex items-center rounded-md border border-transparent px-4 py-2 space-x-2 text-sm font-medium text-white shadow-sm bg-white hover:text-indigo-900 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2"
          >
          <img src={calendarIcon} alt='Calendar Icon'/>
          <img src={messageIcon} alt='Message Icon'/>
          <img src={notificationIcon} alt='Notification Icon'/>                     
        </div>
       
        </div>*/}
      {/* </div>
        </div> */}
        {/* <div className="lg:pl-12">
        </div> */}
        
        <div className='flex flex-row'>
          <div className="px-4 sm:px-6 lg:px-8 overflow-y-hidden bg-white mx-auto w-[75vw] rounded-xl">
                <div className="sm:flex sm:items-center lg:space-x-16">                          
                    <div className="mt-1 py-1 text-sm p-2 lg:w-1/4 text-button-black flex flex-row space-x-3">
                    <h1 className='text-2xl'>Approved PayLater</h1>  
                    </div>
                  {/* <form className="flex w-full lg:ml-0" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search here....
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                      <MagnifyingGlassIcon className="h-6 w-6 pl-2" aria-hidden="true" />
                    </div>
                    <input
                      id="search-field"
                      className="block h-full w-full border-transparent rounded-lg py-2 pl-8 pr-3 placeholder-black focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm px-4 bg-trans-back text-black"
                      placeholder="Search here"
                      type="text"
                      name="search"
                    />
                  </div>
                  </form> */}

                  {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <img src={filterIcon} alt="" />
                  </div>*/}
                </div> 
                <div className="mt-4 flex flex-col">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <TableContainer component={Paper}>
                        <Table className="min-w-full divide-y divide-gray-300">
                          <TableHead className="bg-gray-50">
                            <TableRow>
                              <TableCell  scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              DATE OF PURCHASE
                              </TableCell>
                              <TableCell  scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              MERCHANT NAME
                              </TableCell>
                              <TableCell  scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              AMOUNT
                              </TableCell>
                              <TableCell  scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              REPAYMENT Date
                              </TableCell>
                              <TableCell  scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              ASSIGNED AGENT
                              </TableCell>
                              <TableCell  scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              PAY LATER CONFIRMED
                            </TableCell>
                              <TableCell  scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              PAYMENT STATUS
                            </TableCell>
                              {/* <TableCell scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <p>{'    '}</p>
                              </TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody className="divide-y divide-gray-200 bg-white mb-4 pb-4">
                          
                            {(rowsPerPage > 0 ? loans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : loans ).map((loan) => (
                              <TableRow key={loan.id}>
                                <TableCell className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {loan.createdAt.substring(0,10)}
                                </TableCell>
                                <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> {loan.merchant.user.firstName}{' '}{loan.merchant.user.lastName} </TableCell>
                                <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{loan.totalAmountExpected}</TableCell>
                                <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{loan.repaymentDate && loan.repaymentDate.substring(0,10)} : {""}</TableCell>
                                <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{loan.agent && loan.agent.user.firstName}{' '}{loan.agent && loan.agent.user.lastName}: {""}</TableCell>
                                <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{String(loan.PayLaterApproved) && String(loan.PayLaterApproved)}: {""}</TableCell>
                                <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{loan.paymentStatus}</TableCell>
                                {/* <TableCell className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <Link to={`/dashboard/merchants/${loan.id}`} className="text-indigo-600 hover:text-indigo-900 underline text-md">
                                 <p>View</p>
                                  </Link>
                                </TableCell> */}
                              </TableRow>
                            ))}

                            {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}

                          </TableBody>
                          <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[5, 7, 8, { label: "All", value: -1 }]}
                              colSpan={4}
                              count={loans.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                                native: true,
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                        </Table>
                      </TableContainer>
               
                      </div>
                    </div>
                  </div>
                </div>
          </div>
  
        </div>
      </div>
      {/* End main area */}
    </main>
   
    </div>

     
    </div>
  );
}

export default ApprovedLoans;