import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import payNowService from './payNowService'

const initialState = {
    payNows: [],
    payNow: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

export const getAllPayNowPurchase = createAsyncThunk(
    'payNow/getAll',
    async (_, thunkAPI: any) => {
      try {
        const token = thunkAPI.getState().auth.user.access_token
        return await payNowService.getAllPayNowPurchase(token)
      } catch (error: any) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        return thunkAPI.rejectWithValue(message)
      }
    }  
)
export const getAllRequestDelivery = createAsyncThunk(
    'delivery/getAll',
    async (_, thunkAPI: any) => {
      try {
        const token = thunkAPI.getState().auth.user.access_token
        return await payNowService.getAllRequestDelivery(token)
      } catch (error: any) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        return thunkAPI.rejectWithValue(message)
      }
    }  
)


export const payNowSlice = createSlice({
  name: 'payNow',
  initialState,
  reducers: {
      reset: (state) => initialState
  },
  extraReducers: (builder) => {
      builder.addCase(getAllPayNowPurchase.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllPayNowPurchase.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.payNows = action.payload
        })
        .addCase(getAllPayNowPurchase.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        .addCase(getAllRequestDelivery.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllRequestDelivery.fulfilled, (state,action) => {
          state.isLoading = false
          state.isSuccess = true
          state.payNows = action.payload
        })
        .addCase(getAllRequestDelivery.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
  },
})

export const {reset} = payNowSlice.actions

export default payNowSlice.reducer