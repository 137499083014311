import { Link } from 'react-router-dom'
import right_direction from '../images/right_direction.svg'
import people_red from '../images/people_red.svg'
import people_blue from '../images/people_blue.svg'
import calendarIcon from '../images/calendarIcon.svg'
import messageIcon from '../images/messageIcon.svg'
import notificationIcon from '../images/notificationIcon.svg'
import {MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllAgents, reset } from '../slices/agents/agentSlice'
import { AppDispatch } from '../store/store'
import { getAllMerchants } from '../slices/merchants/merchantSlice'

function Dash() {
  
  const {agents, isLoading, isSuccess} = useSelector(
    (state:any) => state.agents)
    const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(getAllAgents())
  }, [dispatch])

  const {merchants} = useSelector(
    (state:any) => state.merchants)
  useEffect(() => {
    dispatch(getAllMerchants())
  }, [dispatch])
  // console.log(agents)


  return (
        <div className="bg-trans-back">
           <div className=" mt-4 px-4 sm:px-6 lg:px-8 w-full ">
                <div className="flex flex-1 justify-between px-4">
                <div className="flex flex-1">
                  <form className="flex w-full lg:ml-0" action="#" method="GET">
                    <label htmlFor="search-field" className="sr-only">
                      Search...
                    </label>
                    <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                        <MagnifyingGlassIcon className="h-6 w-6 pl-2" aria-hidden="true" />
                      </div>
                      <input
                        id="search-field"
                        className="block h-full w-full border-transparent rounded-lg py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm px-4"
                        placeholder="Search..."
                        type="search"
                        name="search"
                      />
                    </div>
                  </form>
                </div>
                
                <div className="ml-4 flex items-center lg:ml-6">
                  <div
                    className="inline-flex items-center rounded-md border border-transparent px-4 py-2 space-x-2 text-sm font-medium text-white shadow-sm bg-white hover:text-indigo-900 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2"
                  >
                  <img src={calendarIcon} alt='Calendar Icon'/>
                  <img src={messageIcon} alt='Message Icon'/>
                  <img src={notificationIcon} alt='Notification Icon'/>                     
                </div>
               
                </div>
              </div>
                </div>
          <div className="mt-4 mx-4 sm:-mb-24 lg:mb-2 bg-hero-blue rounded-md flex">
                <div className='flex flex-col justify-center lg:h-48 '>
                    <div className="m-12 text-left  font-bold">
                      <p className='text-white text-4xl font-family:Georgia'>Welcome to your dashboard</p>
                    </div>
                    <div className='flex xs:flex-col md:flex-row lg:flex-row mx-auto lg:w-[70vw]  justify-between lg:h-12'>       
                      <div 
                          className="xs:mb-6 xs:ml-1 content-between px-4 py-2 border border-transparent text-base font-medium rounded-lg text-white"
                          >
                          <p className="2xl">Your dashboard allows you to track the progress of every Agent and Merchant</p> 
                      </div>
                      <Link to='/dashboard/agents'
                          className="flex xs:ml-1 rounded-3xl px-4 py-2 border border-transparent text-base font-medium justify-between space-x-2 items-center bg-white text-button-color hover:bg-gray-700"
                          >
                       <p className="2xl">Visit Agent Section</p>  <img src={right_direction} alt="" className='h-3 pr-2'  /> 
                      </Link>

                    </div>
                </div>
               
          </div>
          <div className="mt-2 mx-8 -mb-10 sm:-mb-24 lg:mb-2 bg-white rounded-3xl flex">
                <div className='flex flex-row justify-between mx-auto place-items-center lg:h-48 lg:w-3/4'>
                <div className='flex flex-row place-items-center'>
                  <img src={people_blue} alt='' className='h-16'/>
                  <div className='m-2'>
                    <p>Agents</p>
                    <h1 className='text-3xl'>{agents.length}</h1>
                    {/* <p><span className='text-emerald-400'>37.8%</span> this month</p> */}
                  </div>
                </div>
                <div className='flex flex-row place-items-center'>
                  <img src={people_red} alt='' className='h-16'/>
                  <div className='m-2'>
                    <p>Merchants</p>
                    <h1 className='text-3xl'>{merchants.length}</h1>
                    {/* <p><span className='text-emerald-400'>37.8%</span> this month</p> */}
                  </div>
                </div>
                </div>

          </div>

          <div className='grid grid-cols-3 mx-auto lg:w-3/4 mt-0'>
            <div className='bg-white lg:w-64 lg:h-64 rounded-lg'>
            <p className='grid place-items-center'> Repaid Loans</p>
            <p className='grid place-items-center'>Number of repaid loans</p>
            <div
            className="grid lg:mx-16 place-items-center lg:relative bottom-0 w-1/2  justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
        
              View
            </div>
            </div>
            <div className='bg-white lg:w-64 lg:h-64 rounded-lg'>
            <p className='grid place-items-center'> Approved Loans</p>
            <p className='grid place-items-center'>Approved payLater</p>
            <div
            className="grid lg:mx-16 place-items-center w-1/2  bottom-0  justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              {/* View */}
              <Link to="/dashboard/purchase/approved">
                                 <p>View</p>
                                  </Link>
            </div>
            </div>
            <div className='bg-white lg:w-64 lg:h-64 rounded-lg '>
            <p className='grid place-items-center'> Outstanding Loans</p>
            <p className='grid place-items-center'>Unapproved payLater</p>
            <div
            className="grid lg:mx-16 place-items-center bottom-0 w-1/2  justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
                            <Link to="/dashboard/purchase/outstanding">
                                 <p>View</p>
                                  </Link>
            </div>
            </div>

          </div>
  
          
        </div>
  )
}

export default Dash