import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import loanService from './loanService'

const initialState = {
    loans: [],
    loan: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

export const getAllLoans = createAsyncThunk(
    'loans/getAll',
    async (_, thunkAPI: any) => {
      try {
        const token = thunkAPI.getState().auth.user.access_token
        return await loanService.getAllLoans(token)
      } catch (error: any) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        return thunkAPI.rejectWithValue(message)
      }
    }  
)
export const getAllLoansApproved = createAsyncThunk(
    'loans/getAllApproved',
    async (_, thunkAPI: any) => {
      try {
        const token = thunkAPI.getState().auth.user.access_token
        return await loanService.getAllLoansApproved(token)
      } catch (error: any) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        return thunkAPI.rejectWithValue(message)
      }
    }  
)
export const getAllRequets = createAsyncThunk(
    'loans/getAllRequets',
    async (_, thunkAPI: any) => {
      try {
        const token = thunkAPI.getState().auth.user.access_token
        return await loanService.getAllRequets(token)
      } catch (error: any) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        return thunkAPI.rejectWithValue(message)
      }
    }  
)


export const loanSlice = createSlice({
  name: 'loan',
  initialState,
  reducers: {
      reset: (state) => initialState
  },
  extraReducers: (builder) => {
      builder.addCase(getAllLoans.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllLoans.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.loans = action.payload
        })
        .addCase(getAllLoans.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        .addCase(getAllLoansApproved.pending, (state) => {
            state.isLoading = true
          })
        .addCase(getAllLoansApproved.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.loans = action.payload
        })
        .addCase(getAllLoansApproved.rejected, (state, action: any) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
        .addCase(getAllRequets.pending, (state) => {
            state.isLoading = true
          })
        .addCase(getAllRequets.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.loans = action.payload
        })
        .addCase(getAllRequets.rejected, (state, action: any) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
  },
})

export const {reset} = loanSlice.actions

export default loanSlice.reducer