import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, Outlet } from 'react-router-dom'
import Spinner from 'react-spinkit'
import { getAllAreas, reset } from '../slices/areas/areaSlice'
import notificationIcon from '../images/notificationIcon.svg'
import calendarIcon from '../images/calendarIcon.svg'
import messageIcon from '../images/messageIcon.svg'


function Purchases() {
  // const [modal, setModal] = useState(false)
  const {areas, isLoading, isSuccess} = useSelector(
    (state) => state.areas)


    const dispatch = useDispatch()

    useEffect(() => {
      return () => {
        if (isSuccess) {
          dispatch(reset())
        }
      }
    }, [dispatch, isSuccess])
  
    useEffect(() => {
      dispatch(getAllAreas())
    }, [dispatch])
  
    // console.log(areas)


    if (isLoading) {
      return (
      <div className="grid place-items-center h-full w-full">
      <Spinner 
      name="ball-spin-fade-loader"
      color="blue"
      fadeIn="none"
      className="flex-col pt-32 grid place-items-center items-center justify-center  h-screen"
      />
      </div>
      )
    }



  return (
        <div className="relative z-0 h-screen overflow-scrool bg-trans-back">
            <div className='mt-6'>
                <main className="relative z-0 overflow-y-auto focus:outline-none">
                  {/* Start main area*/}
                  <div className='flex flex-col'>
                    <div className="py-6 px-4 sm:px-6 lg:px-8 w-full ">
                    <div className="flex flex-1 justify-between px-4">
                    <div className="flex flex-1">
                      <form className="flex w-full lg:ml-0" action="#" method="GET">
                        {/* <label htmlFor="search-field" className="sr-only">
                          Search...
                        </label> */}
                        <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                            <MagnifyingGlassIcon className="h-6 w-6 pl-2" aria-hidden="true" />
                          </div>
                          {/* <input
                            id="search-field"
                            className="block h-full w-full border-transparent rounded-lg py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm px-4"
                            placeholder="Search..."
                            type="search"
                            name="search"
                          /> */}
                        </div>
                      </form>
                    </div>
                    
                    <div className="ml-4 flex items-center lg:ml-6">
                      {/* <div
                        type="button"
                        className="inline-flex items-center rounded-md border border-transparent px-4 py-2 space-x-2 text-sm font-medium text-white shadow-sm bg-white hover:text-indigo-900 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2"
                      >
                      <img src={calendarIcon} alt='Calendar Icon'/>
                      <img src={messageIcon} alt='Message Icon'/>
                      <img src={notificationIcon} alt='Notification Icon'/>                     
                    </div> */}
                  
                    </div>
                  </div>
                    </div>
                  </div>
          

                  <div className='w-[60vw] mb-1 border border-button-color mx-auto flex justify-between rounded-md'>
                  <NavLink to='' className={({ isActive }) => isActive ?'w-full py-2 text-button-color flex items-center justify-center hover:bg-button-color hover:text-white': 'w-full py-2 text-white bg-button-color flex items-center justify-center hover:bg-button-color hover:text-white' } >PayNow</NavLink> 
                  <NavLink to='approved' className={({ isActive }) => isActive ? 'w-full py-2 text-white bg-button-color flex items-center justify-center hover:bg-button-color hover:text-white' : 'w-full py-2 text-button-color flex items-center justify-center hover:bg-button-color hover:text-white'} >ApprovedPayLater</NavLink>
                  <NavLink to='outstanding' className={({ isActive }) => isActive ? 'w-full py-2 text-white bg-button-color flex items-center justify-center hover:bg-button-color hover:text-white' : 'w-full py-2 text-button-color flex items-center justify-center hover:bg-button-color hover:text-white'}>PayLaterNotYetApproved</NavLink>
                  <NavLink to='delivery' className={({ isActive }) => isActive ? 'w-full py-2 text-white bg-button-color flex items-center justify-center hover:bg-button-color hover:text-white' : 'w-full py-2 text-button-color flex items-center justify-center hover:bg-button-color hover:text-white'}>RequestDelivery</NavLink>
                </div>
                <Outlet />
                </main>
        
            </div>
          
        </div>
  )
}

export default Purchases