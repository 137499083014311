import axios from 'axios'

const STORE_API = process.env.REACT_APP_STORE_URL || ' '

// Get all 
const getAllProducts = async (token: String) => {
   

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(STORE_API + 'store-product/get-products?skip=0&limit=1000', config)

    return response.data.products
}


const createProduct = async (productData: any, token: String) => {
   

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json"
    },
  }

  const response = await axios.post(STORE_API + 'store-product/create', productData, config)

  return response.data
}






const productService = {
    getAllProducts,
    createProduct
  }


  export default productService