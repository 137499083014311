import axios from 'axios'

const STORE_API = process.env.REACT_APP_STORE_URL || ' '

// Get all 
const getAllPayNowPurchase = async (token: String) => {
   
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(STORE_API + 'purchase/requests?skip=0&limit=1000&order_by=DATE_CREATED&order_direction=DESC&isPayLater=false&paymentStatus=Pending', config)
    return response.data.requests
}

const getAllRequestDelivery = async (token: String) => {
   
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(STORE_API + 'purchase/requests?skip=0&limit=1000&paymentStatus=Pending%20Delivery&order_by=DATE_CREATED&order_direction=DESC', config)
    return response.data.requests
}



const payNowService = {
    getAllPayNowPurchase,
    getAllRequestDelivery
  }
  export default payNowService