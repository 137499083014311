/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch, useSelector  } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createArea } from '../../slices/areas/areaSlice'
import Spinner from 'react-spinkit'

export default function CreateArea() {
  const {isLoading, isSuccess, isError, message} = useSelector((state) => state.areas)
  const [open, setOpen] = useState(true)
  const [formData, setFormData] = useState({
    state: '',
    title: '',
  })

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  } 

  const onSubmit = (e) => {
    e.preventDefault()
    const areaData = {
      state,
      title
    }
    console.log(areaData)
    dispatch(createArea(areaData))
  }


  useEffect(() => {
    if (isError) {
      toast.error(message)
      navigate('/dashboard/areas')
    }
  
    if (isSuccess) {
      toast.success(message)
      navigate('/dashboard/areas')
    }
  
  }, [dispatch, isError, isSuccess, navigate, message])
  

  const { state, title} = formData


  if (isLoading) {
    return (
    <div className="grid place-items-center h-full w-full">
    <Spinner 
    name="ball-spin-fade-loader"
    color="blue"
    fadeIn="none"
    className="flex-col pt-32 grid place-items-center items-center justify-center  h-screen"
    />
    </div>
    )
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-left sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-indigo-600">
                      Create New Area
                    </Dialog.Title>
                    <div className="mt-6">
                  <form action="#" onSubmit={onSubmit} className="space-y-6">
                    <div>
                      <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                        State
                      </label>
                      <div className="mt-1">
                        <input
                          id="state"
                          name="state"
                          type="string"
                          autoComplete="state"
                          required
                          value={state}
                          onChange={onChange}
                          placeholder="State"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
  
                    <div className="space-y-1">
                      <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                        title
                      </label>
                      <div className="mt-1">
                        <input
                          id="title"
                          name="title"
                          type="string"
                          value={title}
                          onChange={onChange}
                          autoComplete="current-title"
                          placeholder='Area name'
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Create
                    </button>
                  </div>
                  </form>
                </div>
                  </div>
                </div>
            
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
