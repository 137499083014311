import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import productService from './productService'

const initialState = {
    products: [],
    product: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

export const getAllProducts = createAsyncThunk(
    'products/getAll',
    async (_, thunkAPI: any) => {
      try {
        const token = thunkAPI.getState().auth.user.access_token
        return await productService.getAllProducts(token)
      } catch (error: any) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        return thunkAPI.rejectWithValue(message)
      }
    }  
)



export const createProduct = createAsyncThunk(
  'products/create',
  async (productData, thunkAPI: any) => {
    try {
      const token = thunkAPI.getState().auth.user.access_token
      return await productService.createProduct(productData,token)
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }  
)






export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
      reset: (state) => initialState
  },
  extraReducers: (builder) => {
      builder.addCase(getAllProducts.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllProducts.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.products = action.payload
        })
        .addCase(getAllProducts.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        .addCase(createProduct.pending, (state) => {
          state.isLoading = true
        })
        .addCase(createProduct.fulfilled, (state) => {
          state.isLoading = false
          state.isSuccess = true
        })
        .addCase(createProduct.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
  },
})

export const {reset} = productSlice.actions

export default productSlice.reducer