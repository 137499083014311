import axios from 'axios'

const STORE_API = process.env.REACT_APP_STORE_URL || ' '

// Get all Stock Request
const getAllStockRequests = async (token: String) => {
   

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(STORE_API + 'store-product/request-stocks?skip=0&limit=1000', config)

    return response.data.data
}



const stockService = {
    getAllStockRequests,
  }


  export default stockService