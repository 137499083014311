import React, { useState } from 'react'
import {  useSelector } from 'react-redux'
import axios from "axios";
import {Link, useNavigate, useParams} from "react-router-dom"
import { toast } from 'react-toastify'


function Product() {
  const navigate = useNavigate()
  const {products, isLoading, message} = useSelector((state) => state.products)
  const {user} = useSelector((state) => state.auth)
  const {productId} = useParams();
  const product = products.find((product) => product.id === productId)

  const [file, setFile] = useState();
  const [image, setImage] = useState()
  const token = user.access_token
  // console.log("This product:" + product);

  const {title, sellingPrice, costPrice, size, description, createdAt, category, ImageFiles, id} = product

  // console.log(id)
  const baseURL = `https://dev-store.boosta.ng/store-product/update/${id}/add-image`
  
  const deleteProductUrl = `https://dev-store.boosta.ng/store-product/delete-product/${id}`

  function handleChange(e) {
    // console.log(e.target.files);
    setFile(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]))
    
}


const config = {
  headers: { 
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data"
  }
 };

const onSubmit = async (e) => {
  e.preventDefault()
  const formData = new FormData();
  formData.append('file', file);
   try {   
    await axios.patch(baseURL, formData, config)
    // console.log(formData)
    toast.success("Image has been uploaded")
    navigate('/dashboard/inventory')

  } catch (e){
    toast.error(e)
  }

}

const onDeleteClick = async (e) => {
  try {   
    await axios.delete(deleteProductUrl, config)
    toast.success("Product has been deleted")
    navigate('/dashboard/inventory')
  } catch (e){
    toast.error("The product can not be deleted, most likely it is already stocked.")
  }
}  

  return (
    <div className="relative z-0 h-screen overflow-hidden bg-trans-back">
    <div className='mt-6'>
    <Link to='/dashboard/inventory' className='rounded-md border m-4 px-2 py-2 bg-white'>Back </Link>
    <div onClick={onDeleteClick} className='rounded-md border m-4 text-red-500 px-2 cursor-pointer w-32 py-2 bg-white'>Delete Product </div>
        <main className="relative z-0 overflow-y-auto focus:outline-none mt-4 grid grid-cols-2">
          {/* Start main area*/}
          <div className=' mx-6 bg-white p-4 rounded-2xl grid space-y-4 text-indigo-600'>
          <h1>Product Name: {title}</h1>
          <h1> Selling Price: {sellingPrice}</h1>
          <h1>Cost Price: {costPrice}</h1>
          <h1> Size: {size}</h1>
          <h1> Description: {description}</h1>
          <h1> Category: {category.title} </h1>

          <h1>Created At: {new Date(product.createdAt).toLocaleString('en-US')}</h1>

        
          {file ? <img src={image} alt=" " /> : null}

          </div>
          <div className='grid'>
          <form action="#" onSubmit={onSubmit}  className="space-y-6">
                    
                  <div className='grid grid-cols-2  mx-4'>
                  <div className='mx-4'>
                  <label htmlFor="files" className="flex text-sm font-medium text-gray-700">
                  Image to be uploaded
                 </label>
                 <div className="mt-1">
                   <input
                     id="file"
                     name="file"
                     type="file"
                     required
                     accept="image/png, image/jpeg, image/jpg"
                     onChange={(e)=> handleChange(e)}
                     placeholder="Images"
                     className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                   />
                 </div>
                 
                      <div className='my-2'>
                        <button
                          type="submit"
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Upload Image
                        </button>
                      </div>
                  </div>
                  <div className='mx-4'>
                  
              
                  </div>
            </div>
          </form>
          </div>
          
         
         

        </main>

    </div>
  
</div>
  )
}

export default Product