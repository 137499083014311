import axios from 'axios'

const STORE_API = process.env.REACT_APP_STORE_URL || ' '

// Get All Stock in Agent Store
const getAllStoreStockA = async (token: String) => {
   

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(STORE_API + 'store-product/agent-store?skip=0&limit=1000', config)
  
    return response.data.data
  }

  const agentStoreService = {
    getAllStoreStockA
  }


  export default agentStoreService