import React, { useState } from 'react'
import { useEffect} from 'react'
import {  useSelector,useDispatch } from 'react-redux'
import {Link, useNavigate, useParams} from "react-router-dom"



function Merchant() {


  const navigate = useNavigate()
  const {merchants} = useSelector((state) => state.merchants)
//   const {areas} = useSelector((state) => state.areas)
  const {user} = useSelector((state) => state.auth)
  const {merchantId} = useParams();
  const merchant = merchants.find((merchant) => merchant.id === merchantId)

  const token = user.access_token

//   const [formData, setFormData] = useState({
//     areaID: 'a3a8f200-92b1-4121-bb5f-ff30a74325d3',
//   })
//   const {areaID} = formData  


// const deleteAgentUrl = `https://dev-onboarding-api.boosta.ng/agents/${merchantId}`


// const updateAgenAreatUrl = `https://dev-onboarding-api.boosta.ng/agents/${agentId}/update-agent`


const config = {
  headers: { 
    Authorization: `Bearer ${token}`,
    // "Content-Type": "multipart/form-data"
  }
 };



 const dispatch = useDispatch()


 
//  useEffect(() => {
//     dispatch(getAllAreas())
//   }, [dispatch])

//   console.log(areas)

//   const onChange = (e) => {
//     e.preventDefault()
//     setFormData((prevState) => ({
//      ...prevState,
//     [e.target.name]: e.target.value,
//     }))
//     console.log(formData)
//   }

//   const onSubmit =  async (e) => {
//     e.preventDefault()
//     const config = {
//       headers: { Authorization: `Bearer ${token}` }
//      };

//     try {
//       const res = await axios.put(updateAgenAreatUrl, formData, config)
//       toast.success("Agent has been Assigned Succesfully")
//       navigate('/dashboard/agents')

//     } catch (e){
//       toast.error(e)
//     }
//   }


// const onDeleteClick = async (e) => {
//   try {   
//     await axios.delete(deleteAgentUrl, config)
//     toast.success("Agent has been deleted")
//     navigate('/dashboard/inventory')
//   } catch (e){
//     toast.error("The product can not be deleted, most likely it is already stocked.")
//   }
// }  

  return (
    <div className="relative z-0 h-screen overflow-hidden bg-trans-back">
    <div className='mt-6'>
    <Link to='/dashboard/inventory' className='rounded-md border m-4 px-2 py-2 bg-white'>Back </Link>
    {/* <div onClick={onDeleteClick} className='rounded-md border m-4 text-red-500 px-2 cursor-pointer w-32 py-2 bg-white'>Delete Agent </div> */}
        <main className="relative z-0 overflow-y-auto focus:outline-none mt-4 grid grid-cols-2">
          {/* Start main area*/}
          <div className=' mx-6 bg-white p-4 rounded-2xl grid space-y-4 text-indigo-600'>
          <h1>First Name   : {merchant.user.firstName}</h1>
          <h1>Middle Name  : {merchant.user.middleName}</h1>
          <h1>Last Name    : {merchant.user.lastName}</h1>
          <h1>Phone Number : {merchant.user.phoneNumber}</h1>
           <h1>Home Address : {merchant.user.profile.homeAddress}</h1>
          <h1>Role         : {merchant.user.role}</h1>
          <h1>Area         :{merchant.area && merchant.area.title}, {merchant.area && merchant.area.state}</h1>
          <h1>Date Onboarded         : {merchant.dateOnboarded.substring(0,10)}</h1> 
           </div>
        {/* <form action="#" onSubmit={onSubmit}>
        <div className="space-y-3 my-4">
                        <label htmlFor="categoryId" className="block text-sm font-medium text-gray-700">
                          Assign Area to this Agent
                        </label>
                        <div className="mt-1">
                          <select name="agentId" id="agentId" defaultValue={'a3a8f200-92b1-4121-bb5f-ff30a74325d3'} onChange={onChange}
                          required 
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                          {areas? areas.map(area => (
                            <option value={area.id} key={area.id}>{area.title}, {area.state}</option>
                          )) :  <option value="Car">Null</option>}
                        
                          </select>
                          
                        </div>
                      </div>



                      <div className='my-2'>
                        <button
                          type="submit"
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Save Area
                        </button>
                      </div>
                  
        </form> */}
         

      </main>

      

     </div>
  
 </div>
  )
}

export default Merchant