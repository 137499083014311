import axios from 'axios'

const STORE_API = process.env.REACT_APP_STORE_URL || ' '

// Get all Stock in store
const getAllStoreStockG = async (token: String) => {
   

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(STORE_API + 'store-product/allStoreStock?skip=0&limit=1000', config)

    return response.data.products
}

const createStoreStock = async (productData: any, token: String) => {
   

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json"
      },
    }
  
    const response = await axios.post(STORE_API + 'store-product/storeStocks', productData, config)
  
    return response.data
  }


const stockGService = {
    getAllStoreStockG,
    createStoreStock
  }


  export default stockGService