import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../slices/auth/authSlice';
import areaReducer from '../slices/areas/areaSlice'
import agentReducer from '../slices/agents/agentSlice'
import productReducer from '../slices/products/productSlice'
import categoryReducer  from '../slices/categories/categorySlice';
import merchantReducer from '../slices/merchants/merchantSlice'
import loanReducer from '../slices/Loans/loanSlice'
import payNowReducer from '../slices/PayNow/payNowSlice'
import stockReducer from '../slices/stock/stockSlice'
import stockGReducer from '../slices/stockGeneral/stockGSlice'
import stockAReducer from '../slices/AgentStore/agentStoreSlice'


export const store = configureStore({
  reducer: {
    auth: authReducer,  
    areas: areaReducer,
    agents: agentReducer,
    merchants: merchantReducer,
    products: productReducer,    
    categories: categoryReducer,                            
    loans: loanReducer,
    payNows:  payNowReducer,
    stocks:stockReducer,
    stockGs:stockGReducer,                     
    stockAs:stockAReducer                  
  },
});

export type AppDispatch = typeof store.dispatch