import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import stockService from './stockService'

const initialState = {
    stocks: [],
    stock: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

export const getAllStockRequests = createAsyncThunk(
    'stocks/getAll',
    async (_, thunkAPI: any) => {
      try {
        const token = thunkAPI.getState().auth.user.access_token
        return await stockService.getAllStockRequests(token)
      } catch (error: any) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        return thunkAPI.rejectWithValue(message)
      }
    }  
)








export const stockSlice = createSlice({
  name: 'stock',
  initialState,
  reducers: {
      reset: (state) => initialState
  },
  extraReducers: (builder) => {
      builder.addCase(getAllStockRequests.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllStockRequests.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.stocks = action.payload
        })
        .addCase(getAllStockRequests.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        
  },
})

export const {reset} = stockSlice.actions

export default stockSlice.reducer