import axios from 'axios'

const ONBOARDING_API = process.env.REACT_APP_ONBOARDING_URL || ' '


const getAllAgents = async (token: String) => {
  
    

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(ONBOARDING_API + 'agents', config)

    return response.data
  }




const agentService = {
    getAllAgents,
  }


  export default agentService