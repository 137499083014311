import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import stockGService from './stockGService'

const initialState = {
    stockGs: [],
    stockG: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

export const getAllStoreStockG = createAsyncThunk(
    'stocks/getAll',
    async (_, thunkAPI: any) => {
      try {
        const token = thunkAPI.getState().auth.user.access_token
        return await stockGService.getAllStoreStockG(token)
      } catch (error: any) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        return thunkAPI.rejectWithValue(message)
      }
    }  
)



export const createStoreStock = createAsyncThunk(
  'stocks/create',
  async (productData, thunkAPI: any) => {
    try {
      const token = thunkAPI.getState().auth.user.access_token
      return await stockGService.createStoreStock(productData,token)
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }  
)






export const stockGSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
      reset: (state) => initialState
  },
  extraReducers: (builder) => {
      builder.addCase(getAllStoreStockG.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllStoreStockG.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.stockGs = action.payload
        })
        .addCase(getAllStoreStockG.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        .addCase(createStoreStock.pending, (state) => {
          state.isLoading = true
        })
        .addCase(createStoreStock.fulfilled, (state) => {
          state.isLoading = false
          state.isSuccess = true
        })
        .addCase(createStoreStock.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
  },
})

export const {reset} = stockGSlice.actions

export default stockGSlice.reducer