import React from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet,useParams ,Link} from 'react-router-dom'
import Spinner from 'react-spinkit'
import notificationIcon from '../images/notificationIcon.svg'
import calendarIcon from '../images/calendarIcon.svg'
import messageIcon from '../images/messageIcon.svg'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import axios from "axios";



function ApproveStock() {

  const navigate = useNavigate()
  const {stocks} = useSelector((state) => state.stocks)
  const {isLoading, message} = useSelector((state) => state.stocks)
  const {user} = useSelector((state) => state.auth)
  const {stockId} = useParams();
  const stock = stocks.find((stock) => stock.id === stockId)

  const [formData, setFormData] = useState({
    stockID: stockId,
    qtyOfStockApproved: stock.qtyRequested,
   
  })

  const { stockID, qtyOfStockApproved} = formData
  const baseURL = 'https://dev-store.boosta.ng/store-product/stock'



  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))

  }

  const token = user.access_token


  const onSubmit =  async (e) => {
    e.preventDefault()
    const config = {
      headers: { Authorization: `Bearer ${token}` }
     };

    try {
      // console.log(formData)
      const res = await axios.patch(baseURL, formData, config)
      toast.success("Stock Request has been approved")
      navigate('/dashboard/stocks')

    } catch (e){
      toast.error("This Stock Request has already been approved.")
    }
 
  }



  if (isLoading) {
    return (
    <div className="grid place-items-center h-full w-full">
    <Spinner 
    name="ball-spin-fade-loader"
    color="blue"
    fadeIn="none"
    className="flex flex-col pt-32 text-center items-center justify-center  h-screen"
    />
    </div>
    )
  }
  return (
    <div className="relative z-0 h-screen overflow-hidden bg-trans-back">
    <div className='mt-6'>
    <Link to='/dashboard/inventory' className='rounded-md border m-4 px-2 py-2 bg-white'>Back </Link>
    {/* <div onClick={onDeleteClick} className='rounded-md border m-4 text-red-500 px-2 cursor-pointer w-32 py-2 bg-white'>Delete Agent </div> */}
        <main className="relative z-0 overflow-y-auto focus:outline-none mt-4 grid grid-cols-2">
          {/* Start main area*/}
          <div className=' mx-6 bg-white p-4 rounded-2xl grid space-y-4 text-indigo-600'>
          <h1>Product Title   : {stock.product.title}</h1>
          <h1>Product Size  : {stock.product.size}</h1>
          <h1>Quantity Requested    : {stock.qtyRequested}</h1>
          <h1>Agent Requesting : {stock.agent.user.firstName}{' '}{stock.agent.user.lastName}</h1>
           {/* <h1>Home Address : {merchant.user.profile.homeAddress}</h1> */}
          {/* <h1>Date Requested         : {stock.dateRequested}</h1> */}
          {/* <h1>Area         :{merchant.area && merchant.area.title}, {merchant.area && merchant.area.state}</h1>
          <h1>Date Onboarded         : {merchant.dateOnboarded.substring(0,10)}</h1> */}
           </div>
        <form action="#" onSubmit={onSubmit}>
        <div className="space-y-3 my-4">
                        <label htmlFor="categoryId" className="block text-sm font-medium text-gray-700">
                          Approve Stock for this Agent
                        </label>
                        <div className="mt-1">
                          <input
                            id="qtyOfStockApproved"
                            name="qtyOfStockApproved"
                            type="number"
                            autoComplete="qtyOfStockApproved"
                            required
                            value={qtyOfStockApproved}
                            onChange={onChange}
                            placeholder="Quantity"
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        
                      </div>
                        <div className="mt-1">
                        <input
                            id="stockId"
                            name="stockId"
                            type="hidden"
                            autoComplete="stockId"
                            required
                            value={stockId}
                            // onChange={onChange}
                            placeholder="Product Name"
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                          
                        </div>
                      </div>



                      <div className='my-2'>
                        <button
                          type="submit"
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Approve
                        </button>
                      </div>
                  
        </form>
         

      </main>

      

     </div>
  
 </div>
  )
  
}

export default ApproveStock