import React, { useState,useEffect } from 'react'

import {  useSelector,useDispatch } from 'react-redux'
import axios from "axios";
import {Link, useNavigate, useParams} from "react-router-dom"
import { toast } from 'react-toastify'
import { getAllCategories } from '../slices/categories/categorySlice'



  function UpdateP() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {products, isLoading, message} = useSelector((state) => state.products)
    const {categories} = useSelector((state) => state.categories)
  const {user} = useSelector((state) => state.auth)
    const {productId} = useParams();
    const product = products.find((product) => product.id === productId)
    
    const { title, size, sellingPrice, costPrice, description,category, id} = product

    // console.log(category)
    const [formData, setFormData] = useState({
      title: title,
      size: size,
      sellingPrice: sellingPrice,
      costPrice: costPrice,
      description: description,
      categoryId:category.id
     
    })
    
    const [image, setImage] = useState(null);

    useEffect(() => {
      dispatch(getAllCategories())
      // console.log(categories)
    
    }, [dispatch])
    
  
    const baseURL = `https://dev-store.boosta.ng/store-product/update/${id}`

     const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
    console.log(formData)
  }

    const token = user.access_token
    console.log(token)
  
    const onSubmit =  async (e) => {
      e.preventDefault()
      const config = {
        headers: { Authorization: `Bearer ${token}` }
       };
  
      try {
        const res = await axios.patch(baseURL, formData, config)
        toast.success("Product has been updated")
        navigate('/dashboard/inventory')
  
      } catch (e){
        toast.error(e)
      }
    }  
  


  return (
    <div className="relative z-0 h-screen overflow-hidden bg-trans-back">
    <div className='mt-6'>
    <Link to='/dashboard/inventory' className='rounded-md border m-4 px-2 py-2 bg-white'>Back </Link>
    <div className='grid ml-2'>

              <div className="mt-6">
                    <form action="#" onSubmit={onSubmit} className="space-y-6">
                    
                  <div className='grid grid-cols-2 bg-white mx-4'>
                  <div className='mx-4'>
                      <div className="space-y-3 my-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          Product Name
                        </label>
                        <div className="mt-1">
                          <input
                            id="title"
                            name="title"
                            type="string"
                            autoComplete="title"
                            required
                            value={formData.title}
                            onChange={onChange}
                            placeholder="Product Name"
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>
                     
                      <div className="space-y-3 my-4">
                        <label htmlFor="categoryId" className="block text-sm font-medium text-gray-700">
                          Category
                        </label>
                        <div className="mt-1">
                          <select name="categoryId" id="categoryId" defaultValue={category.id} onChange={onChange}
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                          {categories.categories ?categories.categories.map(category => (
                            <option value={category.id} key={category.id}>{category.title}</option>
                          )) 
                          :  <option value="Car">Car</option> }
                        
                          </select>
                        </div>
                      </div>

                      <div className='grid grid-cols-2'>
                        <div className="space-y-1 ml-3 my-4">
                        <label htmlFor="costPrice" className="block text-sm font-medium text-gray-700">
                          Cost Price
                        </label>
                        <div className="mt-1">
                          <input
                            id="costPrice"
                            name="costPrice"
                            type="number"
                            value={formData.costPrice}
                            onChange={onChange}
                            autoComplete="current-size"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                        </div>
                        <div className="space-y-1 ml-3 mr-3 my-4">
                        <label htmlFor="sellingPrice" className="block text-sm font-medium text-gray-700">
                          Selling Price
                        </label>
                        <div className="mt-1">
                          <input
                            id="sellingPrice"
                            name="sellingPrice"
                            type="number"
                            value={formData.sellingPrice}
                            onChange={onChange}
                            autoComplete="current-size"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                        </div>
                      </div>
                      <div className='my-4'>
                      <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                      Description
                      </label>
                      <div className="mt-1">
                        <input
                          id="description"
                          name="description"
                          type="textarea"
                          autoComplete="description"
                          required
                          value={formData.description}
                          onChange={onChange}
                          placeholder="Description"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
    
                      <div className='my-2'>
                        <button
                          type="submit"
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Update Product
                        </button>
                      </div>
                  </div>
                  <div className='mx-4'>
                  <div className='my-4'>
                          <label htmlFor="size" className="block text-sm font-medium text-gray-700">
                          Size
                          </label>
                          <div className="mt-1">
                            <input
                              id="size"
                              name="size"
                              type="string"
                              autoComplete="size"
                              required
                              value={formData.size}
                              onChange={onChange}
                              placeholder="Size"
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                          </div>
                  </div>

              
                  </div>
            </div>
                    </form>
                  </div>
          </div>     

    </div>
  
</div>
  )
}

export default UpdateP