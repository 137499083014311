import {createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import categoryService from "./categoryService"


const initialState = {
    categories: [],
    category: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}


export const getAllCategories = createAsyncThunk(
    'categories/getAll',
    async (_, thunkAPI: any) => {
      try {
        return await categoryService.getAllCategories()
      } catch (error: any) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        return thunkAPI.rejectWithValue(message)
      }
    }  
  )

  export const createCategory = createAsyncThunk(
    'categories/create',
    async (categoryData, thunkAPI: any) => {
      try {
        const token = thunkAPI.getState().auth.user.access_token
        return await categoryService.createCategory(categoryData,token)
      } catch (error: any) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        return thunkAPI.rejectWithValue(message)
      }
    }  
  )

  export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
          .addCase(getAllCategories.pending, (state) => {
            state.isLoading = true
          })
          .addCase(getAllCategories.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.categories = action.payload
          })
          .addCase(getAllCategories.rejected, (state, action: any) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
          .addCase(createCategory.pending, (state) => {
            state.isLoading = true
          })
          .addCase(createCategory.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
          })
          .addCase(createCategory.rejected, (state, action: any) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
    },
  })

  export const {reset} = categorySlice.actions

  export default categorySlice.reducer