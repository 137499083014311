import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead  from '@mui/material/TableHead';
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePaginationActions from "../components/TablePaginationActions";
import TablePagination from "@mui/material/TablePagination";
import { useEffect,useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'react-spinkit'
import { getAllStoreStockA } from "../slices/AgentStore/agentStoreSlice";
import { useNavigate } from 'react-router-dom'
import { useParams} from "react-router-dom"
import axios from 'axios';

function AgStore() {
    const navigate = useNavigate()
  const {stockGs, isLoading, isSuccess} = useSelector(
    (state) => state.stockGs)
    const {user} = useSelector((state) => state.auth)
    const {userID} = useParams();
    const stockGAs = stockGs.filter((stockA)=>stockA.agent.user.userID===userID)
    // console.log(stockGAs)

      const baseURL = 'https://dev-store.boosta.ng/store-product/agent-store'

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - stockGAs.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllStoreStockA())
  }, [dispatch])

  console.log("My id is "+ userID)
  const [accountBalance, setAccountBalance] = useState(null);
  const token = user.access_token

    const config = {
      headers: { Authorization: `Bearer ${token}` }
     };

  useEffect(() => {
    const apiUrl = `https://dev-store.boosta.ng/store-product/agent-account-balance?skip=0&limit=1000&order_by=DATE_REQUESTED&order_direction=DESC&Approved=true&agentUserID=${userID}`;

    axios.get(apiUrl,config)
      .then((response) => {
        setAccountBalance(response.data);
      })
      .catch((error) => {
        console.error('Error fetching account balance:', error);
      });
  }, []);

  if (isLoading) {
    return (
    <div className="grid place-items-center h-full w-full">
    <Spinner 
    name="ball-spin-fade-loader"
    color="blue"
    fadeIn="none"
    className="flex-col pt-32 grid place-items-center items-center justify-center  h-screen"
    />
    </div>
    )
  }

  return (

    <div className="relative z-0 h-screen overflow-hidden bg-trans-back">
    
    <div className='mt-6'>
    <main className="relative z-0 overflow-y-auto focus:outline-none">
        <div className="lg:pl-12">
              
        <div className='flex flex-row'>
          <div className="px-4 sm:px-6 lg:px-8 overflow-y-hidden bg-white mx-auto w-[75vw] rounded-xl">
                <div className="sm:flex sm:items-center lg:space-x-16">                          
                    <div className="mt-2 py-4 text-sm p-2 lg:w-1/4 text-button-black flex flex-row space-x-3">
                    <h1 className='text-3xl'>Agent Store</h1>  
                    <p className="absolute top-0 right-8 p-2 bg-blue-500 text-white">
                    Account Balance : # {accountBalance}
                  </p>
                    </div>
        <div className="absolute top-0 right-1">
        </div>
                  {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <img src={filterIcon} alt="" />
                  </div> */}
                </div>
                <div className="mt-8 flex flex-col">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg my-20">
                      <TableContainer component={Paper}>
                        <Table className="min-w-full divide-y divide-gray-300">
                          <TableHead className="bg-gray-50">
                            <TableRow>
                              <TableCell  scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                               Agent Name
                              </TableCell>
                              <TableCell  scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                               Product Title
                              </TableCell>
                              <TableCell  scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Size
                              </TableCell>
                              <TableCell  scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Available Quantity
                            </TableCell>
                              <TableCell scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <p>{'    '}</p>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="divide-y divide-gray-200 bg-white mb-4 pb-4">
                          
                            {(rowsPerPage > 0 ? [...stockGAs].sort((a, b) => a.product.title.localeCompare(b.product.title)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : stockGAs ).map((stockG) => (
                              <TableRow key={stockG.id}>
                                <TableCell className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {stockG.agent.user.firstName}{" "}{stockG.agent.lastName}
                                </TableCell>
                                <TableCell className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {stockG.product.title}
                                </TableCell>
                                <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{stockG.product.size}</TableCell>
                                <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{stockG.qtyAvailable}</TableCell>
                                {/* <TableCell className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <Link to={`/dashboard/merchants/${merchant.id}`} className="text-indigo-600 hover:text-indigo-900 underline text-md">
                                 <p>View</p>
                                  </Link>
                                </TableCell> */}
                              </TableRow>
                            ))}

                            {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}

                          </TableBody>
                          <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[5, 7, 8, { label: "All", value: -1 }]}
                              colSpan={3}
                              count={stockGAs.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                                native: true,
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                        </Table>
                      </TableContainer>
               
                      </div>
                    </div>
                  </div>
                </div>
          </div>
  
        </div>
      </div>
      
      {/* End main area */}
    </main>
   
    </div>

     
    </div>
  );
}

export default AgStore;