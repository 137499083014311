import React from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import Spinner from 'react-spinkit'
import notificationIcon from '../images/notificationIcon.svg'
import calendarIcon from '../images/calendarIcon.svg'
import messageIcon from '../images/messageIcon.svg'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from "axios";



function AddCategory() {
  const [formData, setFormData] = useState({
    title: '',
  })

  const { title} = formData

  const dispatch = useDispatch()
  const navigate = useNavigate()
//   const {categories} = useSelector((state) => state.categories)
  const {isLoading, message} = useSelector((state) => state.categories)
  const {user} = useSelector((state) => state.auth)

  const baseURL = 'https://dev-store.boosta.ng/product-category/create'


  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
    // console.log(formData)
  }
  
  const token = user.access_token
  console.log(token)

  const onSubmit =  async (e) => {
    e.preventDefault()
    const config = {
      headers: { Authorization: `Bearer ${token}` }
     };

    try {
      const res = await axios.post(baseURL, formData, config)
      toast.success("Category has been created")
      navigate('/dashboard/inventory')

    } catch (e){
      toast.error(e)
    }
    // dispatch(createCategory(formData))

    setFormData({
      title: '',
    })

  }


  if (isLoading) {
    return (
    <div className="grid place-items-center h-full w-full">
    <Spinner 
    name="ball-spin-fade-loader"
    color="blue"
    fadeIn="none"
    className="flex flex-col pt-32 text-center items-center justify-center  h-screen"
    />
    </div>
    )
  }

  return (
    <div className="relative z-0 h-screen overflow-hidden bg-trans-back">
    <div className='mt-6'>
        <main className="relative z-0 overflow-y-auto focus:outline-none">
          {/* Start main area*/}
          <div className='flex flex-col'>
            <div className="py-6 px-4 sm:px-6 lg:px-8 w-full ">
            <div className="flex flex-1 justify-between px-4">
            <div className="flex flex-1">
              <form className="flex w-full lg:ml-0" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search...
                </label>
                <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                    <MagnifyingGlassIcon className="h-6 w-6 pl-2" aria-hidden="true" />
                  </div>
                  <input
                    id="search-field"
                    className="block h-full w-full border-transparent rounded-lg py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm px-4"
                    placeholder="Search..."
                    type="search"
                    name="search"
                  />
                </div>
              </form>
            </div>
            
            <div className="ml-4 flex items-center lg:ml-6">
              <div
                type="button"
                className="inline-flex items-center rounded-md border border-transparent px-4 py-2 space-x-2 text-sm font-medium text-white shadow-sm bg-white hover:text-indigo-900 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2"
              >
              <img src={calendarIcon} alt='Calendar Icon'/>
              <img src={messageIcon} alt='Message Icon'/>
              <img src={notificationIcon} alt='Notification Icon'/>                     
            </div>
          
            </div>
          </div>
            </div>
          </div>
  

          <div className='grid ml-2'>

              <div className="mt-6">
                    <form action="#" onSubmit={onSubmit} className="space-y-6">
                    
                  <div className='grid grid-cols-2 bg-white mx-4'>
                  <div className='mx-4'>
                      <div className="space-y-3 my-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          Category Title
                        </label>
                        <div className="mt-1">
                          <input
                            id="title"
                            name="title"
                            type="string"
                            autoComplete="title"
                            required
                            value={title}
                            onChange={onChange}
                            placeholder="Category Title"
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>
                      
                      
                       
                      
                      <div className='my-2'>
                        <button
                          type="submit"
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Save Category
                        </button>
                      </div>
                  </div>
                 
            </div>
                    </form>
                  </div>
          </div>
        
          
        <Outlet />
        </main>

    </div>
  
</div>
  )
}

export default AddCategory
