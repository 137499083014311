import axios from 'axios'

const ONBOARDING_API = process.env.REACT_APP_ONBOARDING_URL || ' '


const getAllMerchants = async (token: String) => {
  
    

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axios.get(ONBOARDING_API + 'merchants/all', config)

    return response.data
  }




const merchantService = {
    getAllMerchants,
  }


  export default merchantService