import { EllipsisVerticalIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Spinner from 'react-spinkit'
import CreateArea from '../components/modals/CreateArea'
import { getAllAreas, reset } from '../slices/areas/areaSlice'
import calendarIcon from '../images/calendarIcon.svg'
import messageIcon from '../images/messageIcon.svg'
import notificationIcon from '../images/notificationIcon.svg'
import filterIcon  from '../images/filterIcon.svg'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead  from '@mui/material/TableHead';
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePaginationActions from "../components/TablePaginationActions";
import TablePagination from "@mui/material/TablePagination";
import { deleteArea } from '../slices/areas/areaSlice'
import { toast } from 'react-toastify'
import {useNavigate} from "react-router-dom"

function Areas() {

  const navigate = useNavigate()
  const [modal, setModal] = useState(false)
  const {areas, isLoading, isSuccess} = useSelector(
    (state) => state.areas)



  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - areas.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

    const dispatch = useDispatch()

    

  const onAreaDeleteClick = (areaId) => {
    dispatch(deleteArea(areaId))
    console.log(areaId)
    toast.success('Area deleted')
    navigate('/dashboard/areas')
  }

    useEffect(() => {
      return () => {
        if (isSuccess) {
          dispatch(reset())
        }
      }
    }, [dispatch, isSuccess])
  
    useEffect(() => {
      dispatch(getAllAreas())
    }, [dispatch])
  
    // console.log(areas)
  
    const onClick = () => {
      setModal(!modal)
    }


    if (isLoading) {
      return (
      <div className="grid place-items-center h-full w-full">
      <Spinner 
      name="ball-spin-fade-loader"
      color="blue"
      fadeIn="none"
      className="flex-col pt-32 grid place-items-center items-center  absolute top-1/2 justify-center  h-screen"
      />
      </div>
      )
    }

  return (
        <div className="relative z-0 h-screen overflow-hidden bg-trans-back">
          <div className='mt-6'>
            <main className="relative z-0 overflow-y-auto focus:outline-none">
              {/* Start main area*/}
              <div className='flex flex-col'>
                <div className="py-6 px-4 sm:px-6 lg:px-8 w-full ">
                <div className="flex flex-1 justify-between px-4">
                <div className="flex flex-1">
                  <form className="flex w-full lg:ml-0" action="#" method="GET">
                    <label htmlFor="search-field" className="sr-only">
                      Search...
                    </label>
                    <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                        <MagnifyingGlassIcon className="h-6 w-6 pl-2" aria-hidden="true" />
                      </div>
                      <input
                        id="search-field"
                        className="block h-full w-full border-transparent rounded-lg py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm px-4"
                        placeholder="Search..."
                        type="search"
                        name="search"
                      />
                    </div>
                  </form>
                </div>
                
                <div className="ml-4 flex items-center lg:ml-6">
                  <div
                    type="button"
                    className="inline-flex items-center rounded-md border border-transparent px-4 py-2 space-x-2 text-sm font-medium text-white shadow-sm bg-white hover:text-indigo-900 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2"
                  >
                  <img src={calendarIcon} alt='Calendar Icon'/>
                  <img src={messageIcon} alt='Message Icon'/>
                  <img src={notificationIcon} alt='Notification Icon'/>                     
                </div>
               
                </div>
              </div>
                </div>
            
                
                <div className='flex flex-row'>
                  <div className="px-4 sm:px-6 lg:px-8 overflow-y-hidden">
                        <div className="sm:flex sm:items-center lg:space-x-16">                          
                            <div className="mt-2 text-sm p-2 lg:w-3/4 text-button-color flex flex-row border space-x-3 border-button-color rounded-md">
                              <img src={filterIcon} alt='filter Icon' className='w-6 h-6'/>
                              <p>Filter</p>
                            </div>
                          <form className="flex w-full lg:ml-0" action="#" method="GET">
                          <label htmlFor="search-field" className="sr-only">
                            Search Areas by Name
                          </label>
                          <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                              <MagnifyingGlassIcon className="h-6 w-6 pl-2" aria-hidden="true" />
                            </div>
                            <input
                              id="search-field"
                              className="block h-full w-full border-transparent rounded-lg py-2 pl-8 pr-3 placeholder-white focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm px-4 bg-button-color opacity-10 text-white"
                              placeholder="Search Areas by Name"
                              type="search"
                              name="search"
                            />
                          </div>
                          </form>

                          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                          <button
                              onClick={onClick}
                              type="button"
                              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            >
                              Create New Area
                          </button>
                            {modal && ( <CreateArea />) }
                          </div>
                        </div>
                        <div className="mt-8 flex flex-col">
                          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                              <TableContainer component={Paper}>
                                <Table className="min-w-full divide-y divide-gray-300">
                                  <TableHead className="bg-gray-50">
                                    <TableRow>
                                      <TableCell scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        State
                                      </TableCell>
                                      <TableCell scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Area
                                      </TableCell>
                                      <TableCell scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                        <EllipsisVerticalIcon className='w-4 text-indigo-600 hover:text-indigo-900'/>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody className="divide-y divide-gray-200 bg-white">
                                  {(rowsPerPage > 0 ? areas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : areas ).map((area) => (
                                    <TableRow key={area.id}>
                                      <TableCell className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {area.state}
                                      </TableCell>
                                      <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{area.title}</TableCell>
                                      <TableCell className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                        <button to="#" className="text-indigo-600 hover:text-indigo-900" onClick={()=>onAreaDeleteClick(area.id)}>
                                        <h1 className='text-red-500'>Delete</h1>
                                        </button>
                                      </TableCell>
                                    </TableRow>
                                  ))}

                                  {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                      <TableCell colSpan={6} />
                                    </TableRow>
                                  )}
                                </TableBody>
                                <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[5, 7, 8, { label: "All", value: -1 }]}
                              colSpan={3}
                              count={areas.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                                native: true,
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                                </Table>
                              </TableContainer>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                  <div className="px-4 sm:px-6 lg:px-8 h-16 bg-white">
                  <div className="sm:flex sm:items-center">
                    <div className="mt-4 sm:mt-4 sm:flex-none">
                      <NavLink
                        to='/dashboard/agents'
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                      >
                        View Agents
                      </NavLink>
                    </div>
                  </div>
            
            </div>
                </div>
              </div>
              {/* End main area */}
            </main>
           
            </div>
          </div>
  )
}

export default Areas