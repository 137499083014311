import * as React from "react";
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'react-spinkit'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead  from '@mui/material/TableHead';
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePaginationActions from "../components/TablePaginationActions";
import TablePagination from "@mui/material/TablePagination";
import { getAllProducts, reset } from '../slices/products/productSlice'
import calendarIcon from '../images/calendarIcon.svg'
import messageIcon from '../images/messageIcon.svg'
import notificationIcon from '../images/notificationIcon.svg'
import filterIcon  from '../images/filterIcon.svg'
import addIcon  from '../images/addIcon.svg'
import checkBoxIcon  from '../images/checkBoxIcon.svg'
import threedotsIcon  from '../images/threedotsIcon.svg'
import { Link } from 'react-router-dom'

function Inventory() {

  
  const {products, isLoading } = useSelector(
    (state) => state.products)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - products.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };




    const dispatch = useDispatch()

  
  
    useEffect(() => {
      dispatch(getAllProducts())
    }, [dispatch])
  
    // console.log(products)
 

    if (isLoading) {
      return (
      <div className="grid place-items-center h-full w-full">
      <Spinner 
      name="ball-spin-fade-loader"
      color="blue"
      fadeIn="none"
      className="flex-col pt-32 grid place-items-center items-center justify-center  h-screen"
      />
      </div>
      )
    }

  return (
        <div className="relative z-0 h-full overflow-scroll bg-trans-back">
          <div className='mt-6'>
            <main className="relative z-0 overflow-y-auto focus:outline-none">
              {/* Start main area*/}
              <div className='flex flex-col'>
                <div className="py-6 px-4 sm:px-6 lg:px-8 w-full ">
                <div className="flex flex-1 justify-between px-4">
                <div className="flex flex-1">
                  <form className="flex w-full lg:ml-0" action="#" method="GET">
                    <label htmlFor="search-field" className="sr-only">
                      Search...
                    </label>
                    <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                        <MagnifyingGlassIcon className="h-6 w-6 pl-2" aria-hidden="true" />
                      </div>
                      <input
                        id="search-field"
                        className="block h-full w-full border-transparent rounded-lg py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm px-4"
                        placeholder="Search..."
                        type="search"
                        name="search"
                      />
                    </div>
                  </form>
                </div>
                
                <div className="ml-4 flex items-center lg:ml-6">
                  <div
                    type="button"
                    className="inline-flex items-center rounded-md border border-transparent px-4 py-2 space-x-2 text-sm font-medium text-white shadow-sm bg-white hover:text-indigo-900 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2"
                  >
                  <img src={calendarIcon} alt='Calendar Icon'/>
                  <img src={messageIcon} alt='Message Icon'/>
                  <img src={notificationIcon} alt='Notification Icon'/>                     
                </div>
               
                </div>
              </div>
                </div>
                <div className="lg:pl-12">
                </div>
                
                <div className='flex flex-row'>
                  <div className="px-2 sm:px-6 lg:px-8 overflow-y-hidden bg-white mx-auto w-[75vw] rounded-xl">
                        <div className="sm:flex sm:items-center lg:ml-auto lg:w-[45vw] lg:space-x-16 mt-2 py-4">                          
                        
                          <div className="mt-2 sm:mt-0 sm:ml-16 sm:flex-none">
                          <Link
                              to='/dashboard/store'                                                                                                     
                              className="inline-flex items-center justify-center rounded-md border ml-1 border-transparent bg-indigo-600 px-2 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            >
                             {/* <img src={addIcon} alt='Add Icon'/> */}
                             Boosta Store
                          </Link>

                          <Link
                              to='/dashboard/agents-store'                                                                                                     
                              className="inline-flex items-center justify-center rounded-md border ml-1 border-transparent bg-indigo-600 px-2 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            >
                             {/* <img src={addIcon} alt='Add Icon'/> */}
                             Agent Store
                          </Link>
                          <Link
                              to='/dashboard/add-product'                                                                                                     
                              className="inline-flex items-center justify-center rounded-md border ml-1 border-transparent bg-indigo-600  px-2 py-2 space-x-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            >
                             <img src={addIcon} alt='Add Icon'/>
                              Add Product      
                          </Link>

                          <Link
                              to='/dashboard/add-category'                                                                                                     
                              className="inline-flex items-center justify-center rounded-md border ml-1 border-transparent bg-indigo-600 px-2 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            >
                             <img src={addIcon} alt='Add Icon'/>
                              Add Category
                          </Link>
                          
                          </div>
                          <div className="mt-2 sm:mt-0 sm:ml-16 sm:flex-none">
                          <img src={filterIcon} alt="" />
                          </div>
                        </div>
                        <div className="mt-8 flex flex-col">
                          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <TableContainer component={Paper}>
                                <Table className="min-w-full divide-y divide-gray-300">
                                  <TableHead className="bg-gray-50">
                                    <TableRow>
                                    <TableCell scope="col" className="py-3.5 pl-4text-gray-900 sm:pl-6">
                                       <img src={checkBoxIcon} alt="CheckBox Icon"/>
                                      </TableCell>
                                      <TableCell scope="col" className="py-3.5 pr-3 text-left text-sm font-normal text-gray-900">
                                       CREATED DATE
                                      </TableCell>
                                      <TableCell scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
                                        PRODUCT NAME
                                      </TableCell>
                                      <TableCell scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
                                      COST PRICE
                                    </TableCell>
                                    <TableCell scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
                                      SELLING PRICE
                                    </TableCell>
                                    <TableCell scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
                                     QUANTITY
                                    </TableCell>
                                    <TableCell scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-gray-900">
                                     STATUS
                                    </TableCell>
                                      <TableCell scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                        <img src={threedotsIcon} alt=""/> 
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody className="divide-y divide-gray-200 bg-white">
                                  {(rowsPerPage > 0 ? products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : products ).map((product) => (
                                    <TableRow key={product.id}>
                                       <TableCell className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                       <img src={checkBoxIcon} alt="CheckBox Icon"/>
                                      </TableCell>
                                      <TableCell className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {new Date(product.createdAt).toLocaleString('en-US')}
                                      </TableCell>
                                      <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.title}</TableCell>
                                      <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.costPrice ? product.costPrice : ''}</TableCell>
                                      <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.sellingPrice ? product.sellingPrice : ''}</TableCell>
                                      <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.size ? product.size : ''}</TableCell>
                                      <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.status === false ? <p className='bg-red-700 pr-0 p-1 border border-transparent text-white w-18 rounded-3xl text-center'>Disabled</p> : <p className='bg-button-color pr-0 p-1 border border-transparent text-white w-18 rounded-3xl text-center'>Enabled</p>}</TableCell>
                                      <TableCell className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                        <Link to={`/dashboard/product/${product.id}`} className="text-indigo-600 hover:text-indigo-900 underline text-md">
                                        <p className="text-indigo-600 underline"> View</p>
                                        </Link>
                                        <Link to={`/dashboard/update/${product.id}`} className="text-indigo-600 hover:text-indigo-900 underline text-md">
                                          <p className="text-indigo-600 underline"> Edit Product</p>
                                         </Link>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                }


                                {emptyRows > 0 && (
                                  <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                  </TableRow>
                                )}
                                </TableBody>

                                <TableFooter>
                                <TableRow>
                                  <TablePagination
                                    rowsPerPageOptions={[5, 7, 8, { label: "All", value: -1 }]}
                                    colSpan={5}
                                    count={products.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                      inputProps: {
                                        "aria-label": "rows per page",
                                      },
                                      native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                  />
                                </TableRow>
                              </TableFooter>
                                </Table>
                                </TableContainer>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
          
                </div>
              </div>
              {/* End main area */}
            </main>
           
            </div>
          </div>
  )
}

export default Inventory