import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import agentService from './agentService'

const initialState = {
    agents: [],
    agent: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

export const getAllAgents = createAsyncThunk(
    'agents/getAll',
    async (_, thunkAPI: any) => {
      try {
        const token = thunkAPI.getState().auth.user.access_token
        return await agentService.getAllAgents(token)
      } catch (error: any) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        return thunkAPI.rejectWithValue(message)
      }
    }  
)



export const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
      reset: (state) => initialState
  },
  extraReducers: (builder) => {
      builder.addCase(getAllAgents.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllAgents.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.agents = action.payload
        })
        .addCase(getAllAgents.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
  },
})

export const {reset} = agentSlice.actions

export default agentSlice.reducer