import React from 'react';
import './App.css';
import Home from './pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// import PrivateRoute from './components/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Dash from './pages/Dash';
import Areas from './pages/Areas';
import Agents from './pages/Agents';
import PrivateRoute from './components/PrivateRoute';
import Inventory from './pages/Inventory';
import Purchases from './pages/Purchases';
// import PendingLoans from './pages/PayNowPurchase';
import OutstandingLoans from './pages/OutstandingLoans';
import ApprovedLoans from './pages/ApprovedLoans';
import AddProduct from './pages/AddProduct';
import Product from './pages/Product';
import AddCategory from "./pages/AddCategory"
// import UpdateProduct from './pages/UpdateP';
import UpdateP from './pages/UpdateP';
import Agent from './pages/Agent';
import Merchants from './pages/Merchants';
import Merchant from './pages/Merchant';
import PayNowPurchase from './pages/PayNowPurchase';
import ApproveStock from './pages/ApproveStock';
import StockRequest from './pages/StockRequest';
import StoreStock from './pages/StoreStock';
import AgentStore from './pages/AgentStore';
import RequestDelivery from './pages/RequestDelivery';
import AgStore from './pages/AgStore';
import AgentTransactions from './pages/AgentTransactions';
import MerchantTransactions from './pages/MerchantTransactions';

function App() {
  return (
    <>
    <Router>
    <div className="h-full min-h-full bg-white">
      <Routes>
        <Route path='/' element={<Home />} />
          <Route path='/dashboard/*' element={<Dashboard/>} >
              <Route path='' element ={<PrivateRoute />}>
                <Route path='' element={<Dash/>}/>
              </Route>
              <Route path='areas' element ={<PrivateRoute />}>
                <Route path='' element={<Areas />}/>
              </Route>
              <Route path='agents' element ={<PrivateRoute />}>
                <Route path='' element={<Agents />}/>
              </Route>
              <Route path='merchants' element ={<PrivateRoute />}>
                <Route path='' element={<Merchants />}/>
              </Route>
              <Route path='stocks' element ={<PrivateRoute />}>
                <Route path='' element={<StockRequest />}/>
              </Route>
              <Route path='store' element ={<PrivateRoute />}>
                <Route path='' element={<StoreStock />}/>
              </Route>
              <Route path='agents-store' element ={<PrivateRoute />}>
                <Route path='' element={<AgentStore />}/>
              </Route>
              
              <Route path='agents/:agentId' element ={<PrivateRoute />}>
                <Route path='' element={<Agent />}/>
              </Route>
              <Route path='agents/store/:userID' element ={<PrivateRoute />}>
                <Route path='' element={<AgStore />}/>
              </Route>
              <Route path='agents/transactions/:userID' element ={<PrivateRoute />}>
                <Route path='' element={<AgentTransactions />}/>
              </Route>
              <Route path='merchants/:merchantId' element ={<PrivateRoute />}>
                <Route path='' element={<Merchant />}/>
              </Route>
              <Route path='merchants/transactions/:userID' element ={<PrivateRoute />}>
                <Route path='' element={<MerchantTransactions/>}/>
              </Route>
              <Route path='stocks/:stockId' element ={<PrivateRoute />}>
                <Route path='' element={<ApproveStock />}/>
              </Route>

            
              <Route path='inventory' element={<PrivateRoute />}>
              <Route path='' element={<Inventory />} /> 
              </Route>
               
          
              <Route path='purchase' element ={<Purchases/>}>
                <Route  path='' element={<PrivateRoute />}>
                <Route path='' element={<PayNowPurchase />}/>
                </Route>
                <Route  path='outstanding' element={<PrivateRoute />}>
                <Route path='' element={<OutstandingLoans />} />
                </Route>
                <Route  path='approved' element={<PrivateRoute />}>
                <Route path='' element={<ApprovedLoans />} />
                </Route>
                <Route  path='delivery' element={<PrivateRoute />}>
                <Route path='' element={<RequestDelivery />} />
                </Route>
              </Route>
              <Route path='add-product' element={<PrivateRoute/>}>
                <Route path='' element={<AddProduct />} />
              </Route>
              <Route path='add-category' element={<PrivateRoute/>}>
                <Route path='' element={<AddCategory />} />
              </Route>

              <Route path='product/:productId' element={<PrivateRoute />}>
                  <Route path='' element={<Product />} />
              </Route>
              <Route path='update/:productId' element={<PrivateRoute />}>
                  <Route path='' element={<UpdateP />} />
              </Route>
              
          </Route>
      <Route path='/login' element={<Login />}/>
      </Routes>
      </div>
    </Router>
    <ToastContainer />
    </>
  );  
}

export default App;