
import { Link } from 'react-router-dom'
import offer_logo from '../images/offer_logo.svg'
import phone from '../images/phone.png'
import shop from '../images/shop_landing.svg'
import shopImage from '../images/shopImages.svg'
import Path from '../images/Path.png'
import playlogo from '../images/playlogo.svg'
import phone_ui from '../images/phone_ui.png'
import phone_ui2 from '../images/phone_ui2.svg'
import iphone_logo from '../images/iphone_logo.svg'
import android_logo from '../images/android_logo.svg'
import Header from '../components/Header'
import Footer from '../components/Footer'
import accessToLoan from '../images/accessToLoan.svg'
import freeDelivery from '../images/freeDelivery.svg'
import affordablePrice from '../images/affordablePrice.svg'
import thumbsUp from '../images/thumbsUp.svg'



const features = [
  {
    name: 'Affordable Price',
    description: 'Pay less, Get  more and save big with prices as affordable as you can find.',
    img: affordablePrice
  },
  {
    name: 'Access to Loan',
    description: 'No money to stock up on items? Buy now and pay later at a convenient duration, low interest and no paper work.',
    img: accessToLoan
  },
  {
    name: 'Free Delivery',
    description: "Come and get free delivery on all your orders. Don't stress, We get your orders delivered to your store and on time, for free!",
    img: freeDelivery
  }
]


export default function Home() {
  return (  
      <>
      <Header/>
      <div className="relative overflow-hidden">
        <div>
          <div className="pt-10 bg-white sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
            <div className="mx-auto max-w-7xl lg:px-8">
              <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                  <div className="lg:py-20">
      
                    <h1 className="mt-4 text-4xl tracking-tight font-bold text-black sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                      <span className="block">Online <span  className='text-hero-blue font-normal'>Shop </span>at your </span>
                      <span className="pb-3 block bg-clip-text">
                        door step
                      </span>
                    </h1>
                    <p className="text-base text-gray-700 sm:text-xl lg:text-lg xl:text-xl">
                     We take care of your wholesale and retail shopping at the best price available and deliver at your door step.
                    </p>
                    <div className="mt-10 sm:mt-12 flex sm:flex-col lg:flex-row space-x-5">
                      <Link to='/'
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-button-color hover:bg-gray-700"
                      >
                      Download App
                      </Link>
                      
                      <div className="flex flex-row space-x-4">
                        <img src={playlogo} alt="" />
                        <p className="text-center flex items-center font-semibold content-center text-black sm:text-xl lg:text-lg xl:text-xl">See how it works</p>
                      </div>
                
                    </div>
                  </div>
                </div>
                <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                  <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 ">
                    {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                    {/* <img
                      className="w-full lg:absolute lg:inset-y-0 lg:left-32 lg:h-full lg:w-1/2 lg:max-w-none"
                      src={shop}
                      alt="a Woman carrying a phone"
                    />
                    <img
                      className="w-full lg:absolute lg:inset-y-0 lg:left-32 lg:h-1/2 lg:w-1/3 lg:max-w-none"
                      src={phone_ui}
                      alt="a Woman carrying a phone"
                    />
                    <img
                      className="w-full lg:absolute lg:inset-y-0 lg:left-20 lg:h-1/2 lg:w-1/3 lg:max-w-none"
                      src={shopImage}
                      alt="a Woman carrying a phone"
                    /> */}
                <div className="mt-20 -mb-10 sm:-mb-48 lg:mt-30  lg:relative flex justify-center">
                  <img className="w-1/3 sm:w-full lg:w-1/2 lg:-mr-5 z-10 lg:-mt-8" src={phone_ui2} alt="a Woman carrying a phone" />
                  
                  <img className="w-1/2 sm:w-full lg:w-1/4 lg:-ml-11 z-20 lg:-mb-12 relative" src={shopImage} alt="a Woman carrying a phone" />
                  <img className="w-1/3 sm:w-full lg:w-4/5 lg:-ml-12 z-10  h-49 lg:mt-1" src={shop} alt="a Woman carrying a phone" />

                </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

              {/* Feature section with grid */}
              <div className="relative bg-white py-16 sm:py-24 lg:py-32">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                What we offer
              </p>
              <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {features.map((feature) => (
                    <div key={feature.name} className="pt-6">
                      <div className="flow-root bg-feature-bg rounded-lg px-6 pb-8">
                        <div className="mt-24">
                          <div>
                            <span className="inline-flex items-center justify-center p-3 rounded-md">
                              <img src={feature.img} alt="" className="h-20 w-20" aria-hidden="true" />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                          <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>


          {/* Feature section with screenshot */}
          <div className="relative bg-white pt-16 sm:pt-24 lg:pt-2">
          
            <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
              <div className="mt-12 -mb-10 sm:-mb-24 lg:mb-80 bg-hero-blue rounded-3xl flex">
                <div className='flex flex-col justify-center lg:h-96 lg:w-2/4'>
                    <div className="m-12 text-left font-bold">
                      
                      <p className='text-white text-5xl font-family:Georgia'>shop with Boost A</p>
                      <p className='text-white text-5xl font-family:Georgia'>app, today</p>
                    </div>
                    <div className='flex xs:flex-col md:flex-row lg:flex-row justify-around lg:h-16'>
                  
                      <Link to='/'
                          className="inline-flex items-center xs:mb-6 xs:ml-1 content-between justify-between px-4 py-2 border lg:w-bxl border-transparent text-base font-medium rounded-lg bg-white text-button-color hover:bg-gray-700"
                          >
                      
                           <img src={iphone_logo} alt="" className='h-8 pr-2'/> 
                            <p className="2xl">Download for IOS</p> 
                      </Link>
                      <Link to='/'
                          className="inline-flex items-center xs:ml-1 content-between rounded-lg  px-4 py-2   border lg:bxl  border-transparent text-base font-medium justify-between  bg-white text-button-color hover:bg-gray-700"
                          >
                            
                       <img src={android_logo} alt="" className='h-8 pr-2'  />  <p className="2xl">Download for Android</p> 
                      </Link>

                    </div>
                </div>

                <div className='-mt-12'>
                <img src={thumbsUp} alt="" className='h-25'/> 
                </div>

                <img
                  className="rounded-lg shadow-xl block ring-1 ring-black ring-opacity-5 lg:h-phone lg:-mt-12 lg:relative sm:h-24"
                  src={phone_ui}
                  alt=""
                />
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
      </>
  )
}
